export const MEETING_PARTICIPANT_BASE_FRAGMENT = `
    fragment meetingParticipantBaseFragment on MeetingParticipant {
        uid
        state
        user {
            uid
            firstName
            lastName
            jobTitle
            employerName
            pictureFileResource {
                uid
                schemaCode
                path
            }
        }
    }
`;
